/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { KTIcon } from "../../../helpers";
import { useLayout } from "../../core";
import { Header } from "./Header";

export function HeaderWrapper() {
  const { config, classes } = useLayout();

  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div
      id="kt_app_header"
      className="app-header pt-2"
      style={{
        background: "#05162b",
        borderBottom: "1px dashed var(--bs-app-sidebar-light-separator-color)",
      }}
    >
      <div
        id="kt_app_header_container"
        className={clsx(
          "flex-lg-grow-1",
          classes.headerContainer.join(" "),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className="d-flex align-items-center d-lg-none ms-n2 me-2"
              title="Show sidebar menu"
            >
              <div
                className="btn btn-icon btn-active-color-primary w-35px h-35px"
                id="kt_app_sidebar_mobile_toggle"
              >
                <KTIcon iconName="abstract-14" className=" fs-1" />
              </div>
              <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0"></div>
            </div>
          </>
        )}

        <div
          id="kt_app_header_wrapper"
          className=" w-100 d-flex align-items-stretch"
        >
          {config.app.header.default?.content === "menu" &&
            config.app.header.default.menu?.display && (
              <div className="app-header-menu align-items-stretch flex-grow-1">
                <Header />
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
