import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderWrapper } from "./components/header";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import { Sidebar } from "./components/sidebar";
import { ThemeModeProvider } from "../partials";
import { PageDataProvider, useLayout } from "./core";
import { reInitMenu } from "../helpers";
import clsx from "clsx";
// import { ToolbarWrapper } from "./components/toolbar";

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  const { config } = useLayout();

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            <HeaderWrapper />
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              <Sidebar />
              <div
                className={clsx(
                  "app-main flex-column flex-row-fluid",
                  config?.app?.main?.class
                )}
                id="kt_app_main"
              >
                <div className="d-flex flex-column flex-column-fluid">
                  {/* <ToolbarWrapper /> */}
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
