import { toAbsoluteUrl } from "../../../../helpers";
import { HeaderUserMenu } from "../../../../partials";

export function MenuInner() {
  return (
    <>
      <div className="pt-3 container text-center align-items-center align-self-start align-self-lg-center">
        <div className="row align-items-center flex-lg-row fs-4 fs-lg-6">
          <div className="col text-start">
            {/* <NavLink title={<FreeBetLogo />} to="/" /> */}
          </div>
        </div>
      </div>
    </>
  );
}
